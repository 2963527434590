import React, { useState } from "react"
import Hero from "../CaseStudy/Hero"
import AboutProject from "../CaseStudy/AboutProject"
import Features from "../CaseStudy/Features"
import TechStack from "../CaseStudy/TechStack"
import AppDisplayImage from "../CaseStudy/AppDisplayImage"
import KeyChallengaes from "../CaseStudy/KeyChallanges"
import Results from "../CaseStudy/Results"
import SolutionSection from "../CaseStudy/SolutionSection"
import BusinessNeeds from "../CaseStudy/BusinessNeeds"
import ContactUs from "../CaseStudy/Features/ContactUs"
import ReviewSection from "../CaseStudy/ReviewSection"
import {
  techTags,
  aboutSections,
  techImages,
  aboutProjectIcons,
  keyChallenges,
  solutions,
  featuresList,
  resultsList,
  businessNeedsList,
} from "./data"

function Trackhero() {
  const [active, setActive] = useState(featuresList[0])

  const clickHandler = feature => {
    setActive(feature)
  }
  return (
    <div>
      <Hero
        techTags={techTags}
        aboutSections={aboutSections}
        title="The Ultimate Automotive SaaS Solution"
        imageUrl="/caseStudy/Trackhero/heroBg.png"
        logoUrl="/caseStudy/Trackhero/heroLogo.svg"
      />
      <AboutProject
        text="A comprehensive cloud-based platform that empowers dealership businesses with complete fleet visibility and streamlined management of experiential events and customers. "
        data={aboutProjectIcons}
      />
      <AppDisplayImage url="/caseStudy/Trackhero/belowAbout.png" />
      <KeyChallengaes
        sideImage={"/caseStudy/Trackhero/keyChallenges.png"}
        data={keyChallenges}
      />
      <AppDisplayImage url="/caseStudy/Trackhero/belowKeyChallenges.png" />
      <SolutionSection data={solutions} />
      <AppDisplayImage url="/caseStudy/Trackhero/belowSolution.png" />
      <Features
        data={featuresList}
        onClickHandler={clickHandler}
        active={active}
      />
      <Results data={resultsList} />
      <TechStack data={techImages} page="container-2" />
      <BusinessNeeds data={businessNeedsList} />
      <ReviewSection
        data={{
          review: `"They use their experience to ensure that we meet our project objectives."`,
          clientName: "Kyle Ehrmann",
          clientInfo: "Founder & CEO",
        }}
      />
      <ContactUs className="no-padding" />
    </div>
  )
}

export default Trackhero
