export const techTags = [
  {
    id: 1,
    name: "Product Design",
  },
  {
    id: 2,
    name: "Design Sprint",
  },
  {
    id: 3,
    name: "Front-End",
  },
  {
    id: 4,
    name: "Back-End",
  },
  {
    id: 5,
    name: "DevOps",
  },
]

export const aboutSections = [
  {
    id: 1,
    heading: "Industry Vertical",
    description: "Automotive, Experiential Event Management, Fleet Management",
  },
  {
    id: 2,
    heading: "Business Model",
    description: "B2B SaaS, B2C",
  },
  {
    id: 3,
    heading: "Our Work",
    description:
      "Product Design, Custom Software Development, DevOps, QA & Testing",
  },
]

export const techImages = [
  {
    id: 1,
    icon: "/caseStudy/Trackhero/tech1.svg",
  },
  {
    id: 2,
    icon: "/caseStudy/Trackhero/tech2.svg",
  },
  {
    id: 3,
    icon: "/caseStudy/Trackhero/tech3.svg",
  },
  {
    id: 4,
    icon: "/caseStudy/Trackhero/tech4.svg",
  },
  {
    id: 5,
    icon: "/caseStudy/Trackhero/tech5.svg",
  },
  {
    id: 6,
    icon: "/caseStudy/Trackhero/tech6.svg",
  },
  {
    id: 7,
    icon: "/caseStudy/Trackhero/tech7.svg",
  },
  {
    id: 8,
    icon: "/caseStudy/Trackhero/tech8.svg",
  },
  {
    id: 9,
    icon: "/caseStudy/Trackhero/tech9.svg",
  },
  {
    id: 10,
    icon: "/caseStudy/Trackhero/tech10.svg",
  },
  {
    id: 11,
    icon: "/caseStudy/Trackhero/tech11.svg",
  },
  {
    id: 12,
    icon: "/caseStudy/Trackhero/tech12.svg",
  },
  {
    id: 13,
    icon: "/caseStudy/Trackhero/tech13.svg",
  },
  {
    id: 14,
    icon: "/caseStudy/Trackhero/tech14.svg",
  },
]

export const aboutProjectIcons = [
  { id: 1, icon: "/caseStudy/Trackhero/about1.svg" },
  { id: 2, icon: "/caseStudy/Trackhero/about2.svg" },
  { id: 3, icon: "/caseStudy/Trackhero/about3.svg" },
]

export const keyChallenges = [
  {
    id: 1,
    title: "Scalability Challenges",
    para: "As the user base grew, scalability issues arose due to the challenges in maintaining the codebase. The addition of new features based on user requests further complicated the code, making it difficult to scale the application.",
  },
  {
    id: 2,
    title: "Platform Compatibility",
    para: "With the need for a performant web application accessible on all platforms, the client faced challenges in ensuring the application was compatible with different platforms while maintaining high performance.",
  },
  {
    id: 3,
    title: "Personalization",
    para: "Providing a personalized experiential auto event while streamlining tedious tasks such as scheduling events, managing rentals, and onboarding attendees was a significant challenge that had to be addressed. The platform needed to be capable of delivering a personalized experience while maintaining high performance and scalability.",
  },
]

export const solutions = [
  {
    id: 1,
    heading: "Architecture Optimization",
    description:
      "We conducted a thorough analysis of the legacy web application to identify and address hot-fixes and optimize the architecture. We implemented an efficient and scalable solution that can handle the growing user base without any issues.",
    icon: "/caseStudy/Trackhero/solution1.svg",
  },
  {
    id: 2,
    heading: "Product Design Strategy",
    description:
      "Our team developed a comprehensive  strategy that incorporated both business profitability and user value. We worked closely with the client to understand their business goals and user needs, ensuring that the final solution met both requirements.",
    icon: "/caseStudy/Trackhero/solution2.svg",
  },
  {
    id: 3,
    heading: "Enhanced Functionality",
    description: `We added new functionality for Dealership Teams as well as the Super Admin, enabling them to manage their operations more efficiently. We also extended their MVP with a new feature set to enhance customer engagement and retention.`,
    icon: "/caseStudy/Trackhero/solution3.svg",
  },
  {
    id: 4,
    heading: "Secure DevOps Workflow",
    description:
      "Our team implemented a secure DevOps workflow that can accommodate ongoing development at a high velocity. This ensures that TrackHero can release new features and updates quickly and securely.",
    icon: "/caseStudy/Trackhero/solution4.svg",
  },
]

export const featuresList = [
  {
    id: 1,
    feature: "Event Management",
    icon: "/caseStudy/Trackhero/feature1.svg",
    icon2: "/caseStudy/Trackhero/feature11.svg",
    image: "/caseStudy/Trackhero/featurebg1.png",
    paragraph:
      "An in-app dashboard that automatically analyzes driver behavioral data and generates personalized reports for improved coaching.",
  },
  {
    id: 2,
    feature: "Real Time Vehicle Queue Management",
    icon: "/caseStudy/Trackhero/feature2.svg",
    icon2: "/caseStudy/Trackhero/feature12.svg",
    image: "/caseStudy/Trackhero/featurebg2.png",
    paragraph:
      "A real-time vehicle queue management feature that enables dealerships to manage their queues efficiently and reduce wait times for customers.",
  },
  {
    id: 3,
    feature: "Rental Management",
    icon: "/caseStudy/Trackhero/feature3.svg",
    icon2: "/caseStudy/Trackhero/feature13.svg",
    image: "/caseStudy/Trackhero/featurebg3.png",
    paragraph:
      "Car reservation with rental settings for each dealership, making it easier for customers to rent cars for their events.",
  },
  {
    id: 4,
    feature: "Dealership Linked as Teams",
    icon: "/caseStudy/Trackhero/feature4.svg",
    icon2: "/caseStudy/Trackhero/feature14.svg",
    image: "/caseStudy/Trackhero/featurebg4.png",
    paragraph:
      "To enable them to collaborate and manage their operations more effectively.",
  },
  {
    id: 5,
    feature: "Fleet Management",
    icon: "/caseStudy/Trackhero/feature5.svg",
    icon2: "/caseStudy/Trackhero/feature15.svg",
    image: "/caseStudy/Trackhero/featurebg5.png",
    paragraph:
      "Enabled vehicle sharing among dealerships to optimize resource utilization and improve operational efficiency.",
  },
  {
    id: 6,
    feature: "Customer Management",
    icon: "/caseStudy/Trackhero/feature6.svg",
    icon2: "/caseStudy/Trackhero/feature16.svg",
    image: "/caseStudy/Trackhero/featurebg6.png",
    paragraph: "We integrated Stripe for secure and efficient payments.",
  },
]

export const resultsList = [
  {
    id: 1,
    heading: "Feature Enhancements",
    description: "Added new functionality for Dealership Teams and Super Admin",
    icon: "/caseStudy/Trackhero/result1.svg",
  },
  {
    id: 2,
    heading: "Web SPA UI",
    description:
      "Built the User Interface from ground up, a Web SPA (Single Page Application), achieving increased fluidity in UX, dynamic data loading and client-side rendering",
    icon: "/caseStudy/Trackhero/result2.svg",
  },
  {
    id: 3,
    heading: "API Connectivity",
    description: `Enabled interconnectivity between client's server and third-party servers via API`,
    icon: "/caseStudy/Trackhero/result3.svg",
  },
  {
    id: 4,
    heading: "Brand Identity",
    description: "Incorporated a fresh brand identity to complement the UI",
    icon: "/caseStudy/Trackhero/result4.svg",
  },
  {
    id: 5,
    heading: "Companion PWA",
    description:
      "Connected customers and dealerships with a Companion PWA (Progressive Web App)",
    icon: "/caseStudy/Trackhero/result5.svg",
  },
]

export const businessNeedsList = [
  {
    id: 1,
    heading:
      "Streamline dealership tasks for increased efficiency and profitability",
    description: "",
    icon: "/caseStudy/Trackhero/business1.svg",
  },
  {
    id: 2,
    heading:
      "Enhance customer experience and engagement to improve retention and loyalty",
    description: "",
    icon: "/caseStudy/Trackhero/business2.svg",
  },
  {
    id: 3,
    heading:
      "Improve management capabilities for better coordination and decision-making",
    description: "",
    icon: "/caseStudy/Trackhero/business3.svg",
  },
  {
    id: 4,
    heading: "Improved accessibility and convenience",
    description: "",
    icon: "/caseStudy/Trackhero/business4.svg",
  },
]
